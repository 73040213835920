import React from 'react'
// import Slugger from "github-slugger"
import { Link } from 'gatsby'

import { IoIosArrowDown } from 'react-icons/io'

// const slugger = new Slugger()

const slug = require('github-slugger').slug

export default ({ title, headings, pathname, color }) => (
  <div class="container-toc mt-2 mb-3 mt-lg-4">
    <div class={`toc-header bg-${color}-1 white-1 p-2 round-corners-top`}>
      {/* {title} */}
    </div>
    <ul
      class={`toc p-2 border-bottom-1-${color}-1  border-left-1-${color}-1  border-right-1-${color}-1 bg-white-1 round-corners-bottom`}
    >
      {headings
        .filter((heading) => heading.depth === 1)
        .map((heading) => (
          <li key={heading.value}>
            <IoIosArrowDown
              size={16}
              className="black-4"
              style={{ marginRight: 3, marginTop: -2 }}
            />
            <Link to={`${pathname}#${slug(heading.value)}`}>
              {heading.value}
            </Link>
          </li>
        ))}
    </ul>
  </div>
)
