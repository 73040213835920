// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React, { useState, useEffect } from 'react'
import { Location } from '@reach/router'

import { Link, graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import Layout from '../components/layout'
// import BoatListingStatRow from "../components/marketing/boatListingStatRow"
// import BoatListingStatTable from "../components/marketing/boatListingStatTable"
// import BoatListingProConTable from "../components/marketing/boatListingProConTable"
// import ModalPhotoViewer from "../components/marketing/modalPhotoViewer"
import TableOfContents from '../components/marketing/tableOfContents'
import InlineImage from '../components/marketing/inlineImage'
import InlineToggle from '../components/marketing/inlineToggle'
import CustomContent from '../components/content/customContent'
import Breadcrumb from '../components/marketing/breadcrumb'

import { MdArrowBack } from 'react-icons/md'
// import { MdPlayCircleOutline } from "react-icons/md"
// import IconBoat from "../images/icon/noun_Boat_2602060.svg"
// import IconSurf from '../images/icon/noun_Surfing_1071441.svg'
// import IconBoard from '../images/icon/noun_Wakeboarding_1023674.svg'
// import IconSki from '../images/icon/noun_Waterskiing_1023669.svg'

const components = (articleimages) => {
  return {
    h1: (props) => <h2 {...props} className="" />,
    h2: (props) => <h3 {...props} className="" />,
    h3: (props) => <h4 {...props} className="" />,
    a: (props) => (
      <a
        {...props}
        href={props.href.replace('http:///', process.env.PRODUCTION_DOMAIN)}
      />
    ),
    p: (props) => <p {...props} className="font-weight-light" />,
    li: (props) => <li {...props} className="font-weight-light" />,
    // Image: props => <InlineImage articleimages={props.articleimages} />,
    Image: ({ ...props }) => {
      console.log('inline image props')
      console.log(props)
      return (
        <InlineImage
          name={props.name}
          caption={props.caption}
          size={props.size}
          articleimages={articleimages}
        />
      )
    },
    Toggle: ({ ...props }) => {
      console.log('toggle image props')
      console.log(props)
      return (
        <InlineToggle
          mobile={props.mobile}
          desktop={props.desktop}
          caption={props.caption}
          size={props.size}
          articleimages={articleimages}
        />
      )
    },
    Content: ({ ...props }) => {
      return <CustomContent name={props.name} />
    },
    End: ({ ...props }) => {
      return <div></div>
    },
    // wrapper: ({ children, ...props }) => {
    //   const tagArray = React.Children.toArray(children).map((child) => {
    //     console.log('**child')
    //     console.log(child)
    //     return child && child.props && child.props.originalType
    //       ? child.props.originalType
    //       : null
    //   })
    //   const indexedHeader = tagArray.reduce(
    //     (a, e, i) => (e === 'h1' ? a.concat(i) : a),
    //     []
    //   )
    //   console.log('**WRAPPER tagArray')
    //   console.log(tagArray)
    //   console.log('**WRAPPER indexedHeader')
    //   console.log(indexedHeader)
    //   console.log('**WRAPPER children')
    //   console.log(children)
    //   console.log('**WRAPPER props.start')
    //   console.log(props.start)
    //   console.log('**WRAPPER props.end')
    //   console.log(props.end)
    //   let filteredChildren
    //   if (!props.end) {
    //     filteredChildren = React.Children.toArray(children).filter(
    //       (item, i) => i >= indexedHeader[props.start || 0]
    //     )
    //   } else {
    //     filteredChildren = React.Children.toArray(children).filter(
    //       (item, i) =>
    //         i >= indexedHeader[props.start || 0] &&
    //         i < indexedHeader[props.end || 1]
    //     )
    //   }

    //   return <>{filteredChildren}</>
    // },
  }
}

const renderMdx = (data, components, start, end, articleimages) => {
  console.log('renderMdx - articleimages')
  console.log(articleimages)
  // return (
  //   <MDXProvider>
  //     <MDXRenderer>{data}</MDXRenderer>
  //   </MDXProvider>
  // )
  // return (
  //   <MDXProvider components={components(articleimages)}>
  //     <MDXRenderer start={start} end={end}>
  //       {data}
  //     </MDXRenderer>
  //   </MDXProvider>
  // )
  return (
    <MDXProvider components={components(articleimages)}>
      <MDXRenderer>{data}</MDXRenderer>
    </MDXProvider>
  )
}

export default ({ data, location }) => {
  console.log('=-=--=data')
  console.log(data)
  const post = data.articles
  const section = data.sections
  const articleimages = data.articleimages.nodes
  // const boatimagesfixed = data.boatimagesfixed.nodes.reverse()

  // const articleimages = []
  const boatimagesfixed = []
  const boatimagesgallery = []
  const boatimagesfixedgallery = []

  console.log('------section')
  console.log(section)

  let boatimagesHeader = {}
  // if (articleimages && articleimages.length > 0) {
  //   console.log('**yes--header')
  //   console.log(
  //     articleimages.filter((image) =>
  //       image.childImageSharp.fluid.originalName.includes('--header')
  //     )
  //   )
  //   boatimagesHeader = articleimages.filter((image) =>
  //     image.childImageSharp.fluid.originalName.includes('--header')
  //   )[0]
  // } else if (articleimages && articleimages[0]) {
  //   console.log('**no--header')

  //   boatimagesHeader = articleimages[0]
  // }
  let contentBody
  let contentHeadings
  if (
    post &&
    post.data &&
    post.data.markdown &&
    post.data.markdown.childMdx &&
    post.data.markdown.childMdx.headings
  ) {
    contentHeadings = post.data.markdown.childMdx.headings
  }
  if (
    post &&
    post.data &&
    post.data.markdown &&
    post.data.markdown.childMdx &&
    post.data.markdown.childMdx.body
  ) {
    contentBody = post.data.markdown.childMdx.body
  }
  console.log('data*****//')
  console.log(data)
  console.log('post*****//')
  console.log(post)
  console.log('contentBody*****//')
  console.log(contentBody)

  // const boatimagesgallery = articleimages.filter(
  //   (image) => !image.childImageSharp.fluid.originalName.includes('SHARED')
  // )
  // const boatimagesfixedgallery = boatimagesfixed.filter(
  //   (image) => !image.childImageSharp.fixed.originalName.includes('SHARED')
  // )

  let gradientColor
  switch (section.edges[0].node.data.color) {
    case 'green':
      gradientColor = 'algae'
      break
    case 'pink':
      gradientColor = 'sunset'
      break
    case 'blue':
      gradientColor = 'cumulus'
      break
    default:
      gradientColor = 'cumulus'
  }

  return (
    <Layout>
      <Helmet
        title={`Storylava - Support - ${post.data.title}`}
        meta={[
          {
            name: 'description',
            content: `Storylava - Support - ${post.data.title}`,
          },
          {
            name: 'keywords',
            content: `Storylava - Support - ${post.data.title}`,
          },
        ]}
      />

      <Breadcrumb
        category={
          section &&
          section.edges &&
          section.edges[0] &&
          section.edges[0].node &&
          section.edges[0].node.data &&
          section.edges[0].node.data.title
            ? section.edges[0].node.data.title
            : ''
        }
        color={
          section &&
          section.edges &&
          section.edges[0] &&
          section.edges[0].node &&
          section.edges[0].node.data &&
          section.edges[0].node.data.color
            ? section.edges[0].node.data.color
            : ''
        }
        categorylink={
          section &&
          section.edges &&
          section.edges[0] &&
          section.edges[0].node &&
          section.edges[0].node.data &&
          section.edges[0].node.data.url
            ? `section/${section.edges[0].node.data.url}`
            : ''
        }
        gradientColor={gradientColor}
      />

      <div class="container container-review my-2 my-4">
        <div class="row d-flex">
          <div class="col-lg-3">
            {boatimagesHeader &&
            boatimagesHeader.childImageSharp &&
            boatimagesHeader.childImageSharp.fluid ? (
              <Img
                className="rounded-corners"
                fluid={boatimagesHeader.childImageSharp.fluid}
              />
            ) : null}
            {/* <div
              class="w-100 bg-gray-5 rounded image-wrapper float-left"
              style={{ height: 300 }}
            ></div> */}
            <div class={`d-none d-lg-block toc-side`}>
              {/* <div class="mt-3 mb-2">
                <Link
                  class={`round-corners bg-${section.edges[0].node.data.color}-1 p-1 pl-2 pr-3 small white-1 d-flex align-items-center`}
                  to={`/section/${section.edges[0].node.data.url}`}
                >
                  <MdArrowBack class="white-1 mr-1" />
                  {section.edges[0].node.data.title}
                </Link>
              </div> */}
              {contentHeadings ? (
                <TableOfContents
                  title={`
            ${post.data.title}`}
                  color={`gray`}
                  headings={contentHeadings}
                  pathname={location.pathname}
                />
              ) : null}
            </div>
          </div>
          <div class="col-lg-9">
            {/* <div class="d-block d-lg-none back-inline">
              <div class="mb-3">
                <Link
                  class={`round-corners bg-${section.edges[0].node.data.color}-1 p-1 pl-2 pr-3 small white-1`}
                  to={`/section/${section.edges[0].node.data.url}`}
                >
                  <MdArrowBack class="white-1 mr-1" />
                  {section.edges[0].node.data.title}
                </Link>
              </div>
            </div> */}

            <h1 class="article-title d-flex flex-row align-items-center justify-content-start my-2">
              {post.data.title}
              {/* <span
                class={`badge badge-sky badge-outline-${
                  post.data.year === '2019' ? 'purple' : ''
                }${post.data.year === '2020' ? 'dkblue' : ''}${
                  post.data.year === '2021' ? 'dkorange' : ''
                } rounded-corners ml-3 font-weight-light`}
              >
                {post.data.year}
              </span> */}
            </h1>
            <hr size={1} />
            <div class="h2 font-weight-light">{post.data.subhead}</div>
            <div class="h5 d-flex align-items-start">
              {/* {post.fields.tagsprocessed.includes("board") ? (
                <span
                  class={`badge badge-outline-sky sky-1 border-1-sky-1 rounded-corners mr-2 font-weight-light d-flex flex-row align-items-center justify-content-center`}
                >
                  <IconBoard
                    class={`fill-color-sky-1 mr-1`}
                    style={{ height: "24px" }}
                  />
                  Wakeboard
                </span>
              ) : null}
              {post.fields.tagsprocessed.includes("surf") ? (
                <span
                  class={`badge badge-outline-dkgreen dkgreen-1 border-1-dkgreen-1 rounded-corners mr-2 font-weight-light d-flex flex-row align-items-center justify-content-center`}
                >
                  <IconSurf
                    class={`fill-color-dkgreen-1`}
                    style={{ height: "24px" }}
                  />
                  Wakesurf
                </span>
              ) : null}
              {post.fields.tagsprocessed.includes("ski") ? (
                <span
                  class={`badge badge-outline-purple purple-1 border-1-purple-1 rounded-corners mr-2 font-weight-light d-flex flex-row align-items-center justify-content-center`}
                >
                  <IconSki
                    class={`fill-color-purple-1 mr-1`}
                    style={{ height: "22px" }}
                  />
                  Waterski
                </span>
              ) : null} */}
            </div>

            {/* <BoatListingStatRow node={post} /> */}

            <div class="d-block d-lg-none toc-inline">
              <div class="mt-1">
                {contentHeadings ? (
                  <TableOfContents
                    title={`
            ${post.data.title}`}
                    color={`gray`}
                    headings={contentHeadings}
                    pathname={location.pathname}
                  />
                ) : null}
              </div>
            </div>

            {contentBody
              ? renderMdx(contentBody, components, 0, 1, articleimages)
              : null}

            {/* <BoatListingStatTable data={post.data} /> */}
          </div>
        </div>
        <div class="row d-flex flex-row flex-wrap align-items-start justify-content-center">
          <div class="col-lg-3">
            {boatimagesfixedgallery ? (
              <div class="p-3 p-md-0 d-flex flex-row flex-wrap align-items-center justify-content-center justify-content-lg-end ">
                {boatimagesfixedgallery.map((image, i) => {
                  // if (i > 0) {
                  return (
                    <button
                      type="button"
                      class="btn btn-modal"
                      data-target="#carouselPhotoControls"
                      data-slide-to={`${i}`}
                    >
                      <span data-toggle="modal" data-target="#photoModal">
                        <Img
                          style={{ maxWidth: 80, maxHeight: 80 }}
                          className="rounded-corners m-1"
                          fixed={image.childImageSharp.fixed}
                        />
                      </span>
                    </button>
                  )
                  // }
                })}
              </div>
            ) : null}
          </div>

          <div class={`col-lg-9`}>
            {/* {contentsplit
              ? contentsplit.map((contentitem, i) => {
                  if (i > 1) {
                    return (
                      <div class="listing-feature-description-short mt-2 font-weight-light">
                        <p>{contentitem}</p>
                      </div>
                    )
                  }
                })
              : null} */}
            {/* <MDXRenderer>{post.data.markdown.childMdx.body}</MDXRenderer> */}
            {/* <MDXProvider components={components}>
              <MDXRenderer start={1}>
                {post.data.markdown.childMdx.body}
              </MDXRenderer>
            </MDXProvider> */}
            {/* {contentBody
              ? renderMdx(contentBody, components, 1, null, articleimages)
              : null} */}
            {/* <div class="font-weight-light gray-5">
              <small>Image credit: {post.data.make}</small>
            </div> */}
          </div>
        </div>
      </div>
      {/* <ModalPhotoViewer data={boatimagesgallery} /> */}
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $sectionid: [String], $imgdir: String) {
    sections: allAirtable(
      filter: {
        table: { eq: "category" }
        data: { catid: { in: $sectionid }, publish: { eq: 1 } }
      }
    ) {
      edges {
        node {
          id
          data {
            publish
            title
            url
            color
          }
        }
      }
    }
    articles: airtable(id: { eq: $id }, table: { eq: "article" }) {
      id
      data {
        title
        url
        category
        publish
        markdown {
          childMdx {
            body
            headings {
              depth
              value
            }
          }
        }
      }
    }
    articleimages: allFile(filter: { relativeDirectory: { eq: $imgdir } }) {
      nodes {
        childImageSharp {
          fluid(quality: 90) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        relativeDirectory
        relativePath
        publicURL
      }
    }
  }
`
// make
//       model
//       review
//       year
//       style
//       rating
//       capacity
//       summaryattr
//       childMdx {
//         body
//       }
