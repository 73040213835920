import React from 'react'
import Img from 'gatsby-image'

export default ({ articleimages, name, caption, size }) => {
  console.log('---------inlineimage--------')
  console.log('name')
  console.log(name)

  console.log('articleimages')
  console.log(articleimages)

  let unescapedName = name.replace('\\', '')
  unescapedName = name.replace('\\_', '_')
  // unescapedName = name.replace('-', '')

  console.log('unescapedName')
  console.log(unescapedName)

  let articleimagesInline = articleimages
    ? articleimages.filter((image) => {
        return image.relativePath.includes(unescapedName)
      })[0]
    : {}

  console.log('articleimagesInline')
  console.log(articleimagesInline)

  return (
    <div
      class={`container-inline-image d-flex flex-column align-items-center justify-content-center`}
    >
      <div
        class={`container-inline-image-inner ${
          size ? `image-inline-${size}` : 'w-100 h-100'
        }  border-1-gray-4 rounded-corners p-1 my-3 bg-gray-7`}
      >
        {articleimagesInline &&
        articleimagesInline.childImageSharp &&
        articleimagesInline.childImageSharp.fluid ? (
          <Img
            className={`rounded-corners w-100 h-100`}
            fluid={articleimagesInline.childImageSharp.fluid}
          />
        ) : null}

        {articleimagesInline && articleimagesInline.childImageSharp === null ? (
          <img class="unmanaged-img" src={articleimagesInline.publicURL} />
        ) : null}

        <div class="caption gray-1 line-height-1 pt-1 px-1 text-center text-lg-left">
          {caption}
        </div>
      </div>
    </div>
  )
}
